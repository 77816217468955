import React, {useState, useEffect, useRef, useContext, Fragment} from 'react'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, DatePicker, DropdownPicker, Switch} from '@eitje/form-fields-web'
import moment from 'moment'
import {Button} from 'antd'
import {useSelector} from 'react-redux'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import useParams from 'hooks/use_params'
import {Spin} from 'antd'
import {ModalForm} from 'initializers/form'
import {all, API} from '@eitje/easy_api'
import InputNumber from 'components/form/input_number'
import useBackgroundEnvs from 'hooks/use_background_envs'

const _Form = props => {
  const envs = useBackgroundEnvs()

  const updateBillingInfo = data => {
    if (data['emails']?.split) {
      data['emails'] = data['emails'].split(',').map(e => e.trim())
    }

    return API.update('billing_infos', data)
  }
  return (
    <ModalForm onSubmit={updateBillingInfo} {...props}>
      <Input required field="company_name" />
      <Input field="emails" />
      <Input field="city" />
      <Input field="street" />
      <Input field="house_number" />
      <Input field="postal_code" />
      <Input field="vat_number" />

      <Switch field="ignore_vat" />
      <Switch field="ignore_mollie" />
      <Switch field="invoice_external" />
      <DropdownPicker
        showSearch
        multiple
        style={{width: 200}}
        required={!props.initialValues}
        labelField="naam"
        label="custom envs"
        items={envs}
        field="environment_ids"
      />
      <Button submitButton> submit </Button>
    </ModalForm>
  )
}

export default _Form
